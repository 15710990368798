export default {
    brand: 'i-mynaui-letter-b-hexagon',
    model: 'i-mynaui-letter-m-hexagon',
    version: 'i-mynaui-letter-v-hexagon',
    bfa: 'i-mdi-factory',
    user: 'i-heroicons-user',
    company: 'i-heroicons-building-office',
    adminUser: 'i-mdi-shield-account-outline',
    dossier: 'i-heroicons-document-text',
    assetType: 'i-mdi-plane-train',
    auction: 'i-streamline-justice-hammer',
    auctionPlanner: 'i-heroicons-calendar-days',
    loading: 'i-svg-spinners-270-ring-with-bg',
    scrapping: 'i-mdi-recycle',
    emailVerified: 'i-mdi-email-check-outline',
};
